import React, { useEffect, useState } from "react"
import * as styles from "./remembrance.module.css"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Spring } from "react-spring/renderprops"
import { animated, config } from "react-spring"
import Search from "../components/searchContainer"
import { Container, Button } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"
import RemembranceForm from "../components/remembranceForm"
import Arrow from "../components/arrow"
import ClientOnly from "../components/clientOnly"

export default function Remembrance({ data }) {
    const [destination, setDestination] = useState(-1)
    const isDestination = d => {
        return d === destination
    }

    const workers = data.allWpWorker.nodes
    const isMobile = useMediaQuery({
        query: "(max-width: 42rem)",
    })
    useEffect(() => {
        window && window.fullpage_api && window.fullpage_api.reBuild()
    }, [])
    return (
        <div>
            <SEO
                title="Remembrance | Forever Essential"
                description="Together we honor the stories of healthcare workers who have lost"
                fbHeadline="Remembrance | Forever Essential"
                fbDescription="Honoring the legacy of healthcare workers lost to COVID-19."
                twShare="Please join me in honoring the legacies of the brave healthcare workers who have lost their lives to COVID-19. #ForeverEssential"
            />
            <ClientOnly>
                <Layout handlerDestination={setDestination}>
                    {/* highlight-start */}
                    {/* <div
                        className="section theirstories_section"
                        style={{
                            background:
                                "linear-gradient(rgba(20,36,51,1), rgba(20,36,51,.2))",
                        }}
                    >
                        <Container>
                            <Spring
                                delay={isDestination(0) ? 100 : 0}
                                to={{
                                    opacity: isDestination(0) ? 1 : 0,
                                    transform: isDestination(0)
                                        ? "translateY(0)"
                                        : "translateY(-150px)",
                                }}
                                config={config.molasses}
                            >
                                {props => (
                                    <animated.div style={{ ...props }}>
                                        <h1
                                            style={{
                                                textAlign: isMobile ? "center" : "left",
                                                fontWeight: "bold",
                                                fontSize: isMobile ? "2.5rem" : "5rem",
                                            }}
                                        >
                                            National COVID Memorial Month
                                        </h1>
                                    </animated.div>
                                )}
                            </Spring>
                            <Spring
                                delay={isDestination(0) ? 1000 : 0}
                                to={{
                                    opacity: isDestination(0) ? 1 : 0,
                                    transform: isDestination(0)
                                        ? "translateY(0)"
                                        : "translateY(-200px)",
                                }}
                                config={config.molasses}
                            >
                                {props => (
                                    <animated.div style={props}>
                                        <h2
                                            style={{
                                                textAlign: isMobile ? "center" : "left",
                                                fontWeight: "normal"
                                            }}
                                        >
                                            This March marks two years since the first COVID-19
                                            cases were recorded among California healthcare workers and
                                            California entered a State of Emergency.
                                        </h2>
                                    </animated.div>
                                )}
                            </Spring>
                            <Spring
                                delay={isDestination(0) ? 1500 : 0}
                                to={{
                                    opacity: isDestination(0) ? 1 : 0,
                                    transform: isDestination(0)
                                        ? "translateY(0)"
                                        : "translateY(-70px)",
                                }}
                                config={config.molasses}
                            >
                                {props => (
                                    <div
                                        style={{
                                            ...props,
                                            textAlign: "center",
                                            marginTop: "15%",
                                        }}
                                    >
                                        <h1>
                                            <Arrow
                                                direction="down"
                                                width="50px"
                                                onClick={e => window.fullpage_api.moveSectionDown()}
                                            />
                                        </h1>
                                    </div>
                                )}
                            </Spring>
                        </Container>
                    </div> */}
                    {/* <div
                        className="section theirstories_section"
                        style={{
                            background:
                                "linear-gradient(rgba(20,36,51,.2), rgba(0,155,165,1))",
                        }}
                    >
                        <Container>
                            <Spring
                                delay={isDestination(1) ? 1200 : 0}
                                to={{
                                    opacity: isDestination(1) ? 1 : 0,
                                    transform: isDestination(1)
                                        ? "translateY(0)"
                                        : "translateY(-150px)",
                                }}
                                config={config.molasses}
                            >
                                {props => (
                                    <animated.div style={{
                                        ...props,
                                        textAlign: "center",
                                    }}>
                                        <h2
                                            style={{
                                                textAlign: "center",
                                                fontWeight: "normal"
                                            }}
                                        >
                                            Through online and in-person memorials, SEIU-UHW is helping Californians pay respect
                                            to the healthcare workers and patients we have lost to the virus. Please join us for
                                            this Month of Remembrance as we honor their legacies.
                                        </h2>
                                            <Button className={styles.button} href="/events/#2" variant="outline-dark">Attend a Memorial Event</Button>
                                    </animated.div>
                                )}
                            </Spring>
                            <Spring
                                delay={isDestination(1) ? 1500 : 0}
                                to={{
                                    opacity: isDestination(1) ? 1 : 0,
                                    transform: isDestination(1)
                                        ? "translateY(0)"
                                        : "translateY(-70px)",
                                }}
                                config={config.molasses}
                            >
                                {props => (
                                    <div
                                        style={{
                                            ...props,
                                            textAlign: "center",
                                            marginTop: "15%",
                                        }}
                                    >
                                        <h1>
                                            <Arrow
                                                direction="down"
                                                width="50px"
                                                onClick={e => window.fullpage_api.moveSectionDown()}
                                            />
                                        </h1>
                                    </div>
                                )}
                            </Spring>
                        </Container>
                    </div> */}
                    <div
                        className={`section theirstories_section fp-auto-height`}
                        style={{
                            background:
                                "linear-gradient( rgba(0,155,165,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(0,155,165,1) 100%)",
                        }}
                    >
                        <Spring
                            delay={isDestination(0) ? 100 : 0}
                            to={{
                                opacity: isDestination(0) ? 1 : 0,
                                transform: isDestination(0)
                                    ? "translateY(0)"
                                    : "translateY(40px)",
                            }}
                            config={config.molasses}
                        >
                            {props => (
                                <animated.div style={{ ...props }}>
                                    <div style={{ minHeight: "120vh", padding: "40px 0" }}>
                                        <Container>
                                            <h2
                                                style={{
                                                    textAlign: "center",
                                                    fontWeight: "normal",
                                                    paddingTop: isMobile ? "20px" : "",
                                                    color: "#142433",
                                                    paddingBottom: "20px"
                                                }}
                                            >
                                                These are the healthcare workers and patients we have lost to COVID-19.
                                            </h2>
                                        </Container>
                                        {workers && workers.length > 0 ? (
                                            <Search workerList={workers} noSearchBar={true}/> 
                                        ) : (
                                            <div />
                                        )}
                                    </div>
                                </animated.div>
                            )}
                        </Spring>
                    </div>
                    <div
                        className="section theirstories_section"
                        style={{
                            background:
                                "linear-gradient(180deg, rgba(0,155,165,1) 0%, rgba(3,140,150,1) 2%, rgba(20,36,51,.9) 100%)",
                            paddingTop: "20px",
                        }}
                    >
                        <Spring
                            delay={isDestination(1) ? 100 : 0}
                            to={{
                                opacity: (isDestination(1) || isDestination(4)) ? 1 : 0,
                                transform: isDestination(1)
                                    ? "translateY(0)"
                                    : "translateY(40px)",
                            }}
                            config={config.molasses}
                        >
                            {props => (
                                <animated.div style={{ ...props }}>
                                    <RemembranceForm />
                                </animated.div>
                            )}
                        </Spring>
                    </div>
                </Layout>
            </ClientOnly>
        </div>
    )
}

export const pageQuery = graphql`
  query RemembranceQuery {
    allWpWorker(sort: { fields: [title], order: ASC }) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 250, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        workerData {
          job
          city
        }
      }
    }
  }
`
